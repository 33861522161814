.homepage {
		.card:before {
				opacity: .4;
				background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(229,229,229,0) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%,rgba(229,229,229,0) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(229,229,229,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00e5e5e5',GradientType=0 ); /* IE6-9 */
		}

		.card-title {
				color: white;
		}
}
