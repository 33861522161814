.pagination {
		.page-item {
				cursor: pointer;

				&.active {
						cursor: default;
						
						.page-link {
								background-color: #28a745;
								border-color: #28a745;
								color: #fff;
								z-index: -1;
						}
				}
				
		} 
		.page-link {
				color: #2b2b2b;

				&:focus {
						box-shadow: none;
				}
		}
}