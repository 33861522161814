@import '../../assets/styles/colors.scss';

header.header {
	background-color: $primary_dark_blue;
	border-bottom: 1px solid $primary_dark_blue;
	color: #fff;
}

.navbar {
	// border-bottom: 1px solid #3fab2e;
	padding-left: 0;
	padding-right: 0;
}

.header a {
	color: inherit;
}

.header .logo {
	width: 200px;
}

.navbar-nav {
	padding: 0 10px!important;
}

.nav-link {
	padding: 7px!important;
}

.nav-link:hover {
	color: $primary_blue!important;
}

.active_header_item {
	border-bottom: 1px solid $primary_red;
}

#navbarDropdown {
	color: $primary_blue!important;
	cursor: pointer;
}
