@import 'colors';
@import 'fonts/index.scss';

#map {
	height: 100%;
}

body {
	margin: 0;
	padding: 0;
	font-family: "Rubik Regular", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

sup, sub {
	top: 0;
	vertical-align: super;
	position: inherit;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
			monospace;
}

.green {
	color: $green!important;
}

.blue {
	color: $blue !important;
}

.red {
	color: $red !important;
}

.row-small {
	margin-left: -7px;
	margin-right: -7px;
}

.row-small [class*="col-"] {
	padding-left: 7px;
	padding-right: 7px;
}

.vertical {
	flex-direction: column;
}

.horizontal {
	flex-direction: row;
}

.vertical-centered {
	display: flex;
	align-items: center;
}

.horizontal-centered {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex {
	display: flex;
}

.flex-vertical-center {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.flex-flow {
	flex-flow: wrap;
}

.flex-space-between {
	display: flex;
	justify-content: space-between;
}

.col-form-label {
	font-size: 14px;
}

.form-control[readonly] {
	background: none;
	border: 0;
	padding-left: 0;
	padding-right: 0;
}

.input-group.is-invalid~.invalid-feedback {
	display: block;
}

.table-sm-padding tr th,
.table-sm-padding tr td {
	padding: .25rem;
}

table tr td {
	position: relative;
}

.icon-status {
	font-size: 12px;

	table tr td & {
		position: absolute;
		top: 5px;
		right: 5px;
	}

	&:before {
		content: '';
		width: 5px;
		height: 5px;
		background-color: #000;
		border-radius: 50%;
		display: inline-block;
		vertical-align: middle;
		margin-right: 3px;
	}

	&.green {
			&:before {
					background-color: $green;
			}
	}

	&.red {
			&:before {
					background-color: $primary_red;
			}
	}
}

.flex-card {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: center;
		border: 1px solid lightgrey;
		height: 100%;
}

.react-datepicker__input-container > input {
		font-size: 13px;
		border-radius: 4px;
		box-shadow: inset 0 2px 2px #e9e9e9;
		border: 1px solid #aeaeae;
		line-height: 16px;
		padding: 6px 10px 5px;
		width: 100%
}

.btn-sm {
		padding: 0.05rem 0.5rem!important;
}



body {
	background-color: #ececec,
}

.font-red {
	color: $primary_red
}

.font-green {
	color: $green
}

.bg-dark-blue {
	background-color: $primary_dark_blue;
}

.font-orange {
	color: $primary_orange;
}

hr {
	margin-top: 25px;
	margin-bottom: 25px;
}

select[disabled], input[disabled] {
		background-color: #f2f2f2;
		border-color: hsl(0,0%,90%);
		cursor: not-allowed;
		color: #a4a4a4;
}

.card-header {
		padding: 0.2rem 0.4rem;
}

.pagination .page-item.active .page-link {
		z-index: 1!important;
}

.invalid-feedback {
		display: block;
		width: 100%;
		margin-top: .25rem;
		font-size: 80%;
		color: #dc3545;
}

.hidden-block {
		display: none;
}

.alert-paragraph {
		margin-bottom: 0;
		font-size: 13px;
}

.info-toggler {
				cursor: pointer;
		.alert {
				position: absolute;
				z-index: 100;
				// min-width: 450px;
		}

		&:not(:first-child) .alert {
				left: -35px;
		}

		&:not(:last-child) {
				float: left;
		}
}

.dropdown-alert {
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

@media screen
and (max-width: 1200px)
and (min-width: 1000px) {
		.info-toggler {
				.alert {
						position: absolute;
						z-index: 100;
				}
		}
}

@media screen
and (max-width: 1000px)
and (min-width: 760px) {
		.info-toggler {
				.alert {
						position: absolute;
						z-index: 100;
				}
		}
}

@media screen
and (max-width: 760px)
and (min-width: 480px) {
		.info-toggler {
				.alert {
						position: absolute;
						z-index: 100;
				}
		}
}

@media screen
and (max-width: 480px)
and (min-width: 320px) {
		.info-toggler {
				.alert {
						position: absolute;
						z-index: 100;
				}
		}
}

.box-booking-bg {
	background-image: url('/img/header.jpg');
	width: 100%;
	max-height: 350px;
	height: 350px;
	background-position: bottom;
	/* Make the background image cover the area of the <div>, and clip the excess */
	background-size: cover;
	margin-top: 90px;
}


.overlay {
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				position: fixed;
				background: #222;
		z-index: 1000000;
		opacity: 0.3;
}

.overlay__inner {
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				position: absolute;
}

.overlay__content {
				left: 50%;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
}

.spinner {
				width: 75px;
				height: 75px;
				display: inline-block;
				border-width: 2px;
				border-color: rgba(255, 255, 255, 0.05);
				border-top-color: #fff;
				animation: spin 1s infinite linear;
				border-radius: 100%;
				border-style: solid;
}

.callback-btn-wrapper {
		position: fixed;
		bottom: 20px;
		right: 20px;
	z-index: 100;
}

@keyframes spin {
		100% {
				transform: rotate(360deg);
		}
}


.rc-slider-track		{
	background-color: $green!important;
}

.rc-slider-handle {
	border: solid 2px $green !important;
}

.rc-slider-dot-active {
	border-color: $green !important;
}

.rc-slider-handle {
	width: 25px !important;
	height: 25px !important;
	margin-top: -11px !important;
}

.rc-slider-mark {
	font-size: 14px !important;
}

.fs-9 {
		font-size: 9rem;
}

.fs-025 {
		font-size: 1.25rem;
}

.fs-2 {
		font-size: 1.5rem;
}

.w-40 {
		width: 30%;
}

.w-60 {
		width: 70%;
}

.payment-label:after {
		content: ':';
}

.callback-btn {
		border-radius: 100%;
		font-size: 2.5rem;
		border: solid 5px rgb(23,162,184);
		animation: play 2s ease infinite;
}

.translation-less {
		background-color: yellow!important;
		color: black;
}

@keyframes play {
	0% {
		transform: scale(1);
	}
	15% {
		box-shadow: 0 0 0 5px rgba(23,162,184, 0.4);
	}
	25% {
		box-shadow: 0 0 0 10px rgba(23,162,184, 0.4), 0 0 0 20px rgba(23,162,184, 0.2);
	}
	25% {
		box-shadow: 0 0 0 15px rgba(23,162,184, 0.4), 0 0 0 30px rgba(23,162,184, 0.2);
	}
}

.relative {
		position: relative;
}

.toggler:hover > .toggler-body {
		display: block;
}

.toggler-body {
		display: none;
		font-size: 14px;
}

.alert-dismissible {
		padding-right: 1.25rem;
}

.disabled {
		cursor: not-allowed;
}

tbody:after, tbody:before {
		overflow-x: scroll;
}

.contract-list td {
		z-index: auto;
}

.authorize-btn {
		margin-bottom: 2px;
}

.system-notification {
		position: fixed;
		top: 20px;
		width: 60%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 10;
}

div[class$="-menu"] {
		z-index: 10;
}

.overflow-notification-cell {
		width: 30%;
}

.grey {
		color: rgb(78, 77, 77);
}

.d {
		&-block {
				display: block;
		}

		&-none {
				display: none;
		}
}

.breadcrumb_item {
		cursor: pointer;
}

.blurred {
		opacity: 0.5;
}

.btn-success {
	color: #fff !important;
	background: $primary_orange !important;
	border-color: $primary_orange !important;

	&:hover {
		color: #fff;
		background: $primary_blue !important;
		border-color: $primary_blue !important;
	}
}

.btn-secondary {
	color: #fff !important;
	background: $primary_dark_blue !important;

	&:hover {
		color: #fff;
	}
}

.btn-primary {
	color: #fff !important;
	background: $primary_blue !important;

	&:hover {
		color: #fff;
	}
	border: none!important;
}

.btn-outline-primary {
	color: $primary_blue !important;
	border-color: $primary_blue !important;

	&:hover {
		color: #fff !important;
		background: $primary_blue !important;
	}
}

.min-w-50 {
	min-width: 50px;
}

#dropdown-basic {
	color: white;

	img {
		margin-bottom: 3px;
	}
}

#dropdown-basic:focus {
	outline: 0!important;
}

.boxer {
  display: table;
  border-collapse: collapse;
	width: 100%;
}
.boxer .box-row {
  display: table-row;
}

.boxer .box-row:first-child {
  font-weight:bold;
}

.boxer .box {
  display: table-cell;
  vertical-align: top;
  border: 1px solid black;
  padding: 5px;
  font-family:monospace;
}
.center {
  text-align:center;
}

.right {
  float:right;
}

.ant-btn-primary:not([disabled]) {
	border-color: $primary_blue!important;
	background: $primary_blue!important;
}
