$green: #00B74F;

$black: #333;
$white: #fff;
$blue: #013a82;
$red: #B22222;

$primary_dark_blue: #002d3a;
$primary_red: #F93822;
$primary_blue: #007396;

$primary_orange: #ff6700;
